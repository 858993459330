var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{staticClass:"view-data payment-line",attrs:{"gutter":15}},[_c('a-row',{attrs:{"gutter":15}},[_c('a-col',{attrs:{"span":20}},[_c('a-col',{attrs:{"span":3}},[_c('div',{staticClass:"label"},[_vm._v("ID")]),_vm._v(" "),_c('div',{staticClass:"value"},[_vm._v("\n                            "+_vm._s(_vm.installment.id)+"\n                        ")])]),_vm._v(" "),_c('a-col',{attrs:{"span":3}},[_c('div',{staticClass:"label"},[_vm._v("Parcela")]),_vm._v(" "),_c('div',{staticClass:"value"},[_vm._v("\n                            "+_vm._s(_vm.installment.installment_number)+"\n                        ")])]),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('div',{staticClass:"label"},[_vm._v("Qtd. de milhas")]),_vm._v(" "),_c('div',{staticClass:"value"},[_vm._v("\n                            "+_vm._s(_vm.installment.miles_quantity)+"\n                        ")])]),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('div',{staticClass:"label"},[_vm._v("Valor a pagar")]),_vm._v(" "),_c('div',{staticClass:"value"},[_vm._v("R$ "+_vm._s(_vm.installment.value))])]),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('div',{staticClass:"label"},[_vm._v("Data de Vencimento")]),_vm._v(" "),_c('a-form-item',{staticClass:"travel-input-outer"},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    `expires_at`,
                                    {
                                        initialValue: _vm.installment.expires_at
                                            ? _vm.installment.expires_at
                                            : '',
                                    },
                                ]),expression:"[\n                                    `expires_at`,\n                                    {\n                                        initialValue: installment.expires_at\n                                            ? installment.expires_at\n                                            : '',\n                                    },\n                                ]"}],staticClass:"travel-input",attrs:{"placeholder":`Data de Vencimento`,"format":"DD/MM/YYYY","disabled":_vm.isPaid(),"showToday":false,"allowClear":false}})],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":5}},[_c('div',{staticClass:"label"},[_vm._v("ID Voyage")]),_vm._v(" "),_c('a-form-item',{staticClass:"travel-input-outer"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'voyage_id',
                                    {
                                        initialValue:
                                            _vm.installment.voyage_id != 0
                                                ? _vm.installment.voyage_id
                                                : '',
                                    },
                                ]),expression:"[\n                                    'voyage_id',\n                                    {\n                                        initialValue:\n                                            installment.voyage_id != 0\n                                                ? installment.voyage_id\n                                                : '',\n                                    },\n                                ]"}],staticClass:"travel-input",attrs:{"type":"number","placeholder":"Insira o ID","disabled":_vm.isPaid()}},[(!_vm.isPaid())?_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"}):_vm._e()],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('div',{staticClass:"label"},[_vm._v("Status do pagamento")]),_vm._v(" "),_c('a-form-item',{staticClass:"travel-input-outer",class:_vm.form.getFieldValue('status')},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    'status',
                                    {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Obrigatório',
                                            },
                                        ],
                                    },
                                ]),expression:"[\n                                    'status',\n                                    {\n                                        rules: [\n                                            {\n                                                required: true,\n                                                message: 'Obrigatório',\n                                            },\n                                        ],\n                                    },\n                                ]"}],staticClass:"travel-input",attrs:{"show-search":"","placeholder":"Selecione","disabled":_vm.$root.isAdmin() || _vm.$root.isFinancial()
                                        ? false
                                        : _vm.isPaid()}},[_c('a-select-option',{attrs:{"value":"canceled"}},[_vm._v("\n                                    Cancelado\n                                ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"paid"}},[_vm._v("\n                                    Pago\n                                ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"pending"}},[_vm._v("\n                                    Pendente\n                                ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"refund"}},[_vm._v("\n                                    Reembolso\n                                ")]),_vm._v(" "),_c('a-select-option',{attrs:{"value":"vcm"}},[_vm._v("\n                                    VCM\n                                ")])],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":10}},[_c('div',{staticClass:"label"},[_vm._v("Comprovante pagamento")]),_vm._v(" "),(_vm.paymentProof)?_c('div',{staticClass:"uploaded-file mt-10",staticStyle:{"font-size":"10px","line-height":"1.2"}},[_c('a',{attrs:{"href":_vm.installment.payment_proof,"target":"_blank"}},[_c('a-icon',{staticClass:"cblue",staticStyle:{"font-size":"30px"},attrs:{"type":"file-done"}})],1),_vm._v(" "),(_vm.installment.status != 'paid')?_c('a-icon',{attrs:{"type":"close-circle"},on:{"click":function($event){_vm.paymentProof = ''}}}):_vm._e()],1):_vm._e(),_vm._v(" "),(!_vm.paymentProof)?_c('div',[_c('a-upload',{attrs:{"name":"file","multiple":false,"action":`https://api.viajar.tur.br/v1/media/upload-to-s3?module=miles-cards-payments&id=${_vm.installment.id}`,"remove":_vm.removeUploadedFile,"headers":{
                                    authorization: `Bearer ${_vm.token}`,
                                }},on:{"change":_vm.uploadedFile},model:{value:(_vm.paymentProof),callback:function ($$v) {_vm.paymentProof=$$v},expression:"paymentProof"}},[_c('a-button',{staticClass:"mt-5",attrs:{"type":"dashed","shape":"circle"}},[_c('a-icon',{attrs:{"type":"file"}})],1)],1)],1):_vm._e()]),_vm._v(" "),(_vm.form.getFieldValue('status') == 'refund')?_c('a-col',{attrs:{"span":24}},[_c('div',{staticClass:"label"},[_vm._v("Motivo do reembolso")]),_vm._v(" "),_c('a-form-item',{staticClass:"textarea mb-0"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                    `observation`,
                                    {
                                        initialValue:
                                            _vm.installment.observation,
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Obrigatório',
                                            },
                                        ],
                                    },
                                ]),expression:"[\n                                    `observation`,\n                                    {\n                                        initialValue:\n                                            installment.observation,\n                                        rules: [\n                                            {\n                                                required: true,\n                                                message: 'Obrigatório',\n                                            },\n                                        ],\n                                    },\n                                ]"}],staticClass:"travel-textarea",attrs:{"placeholder":"Escreva o motivo do reembolso","type":"textarea"}})],1)],1):_vm._e()],1),_vm._v(" "),_c('a-col',{attrs:{"span":4}},[_c('a-button',{staticClass:"mt-10",staticStyle:{"width":"92%"},attrs:{"type":"primary","html-type":"submit","disabled":_vm.$root.isAdmin() || _vm.$root.isFinancial()
                                ? false
                                : _vm.isPaid(),"loading":_vm.loading}},[_vm._v("\n                        Atualizar\n                    ")]),_vm._v(" "),_c('a-checkbox',{staticClass:"mt-10",staticStyle:{"font-size":"12px","font-weight":"500","position":"relative"},attrs:{"disabled":true},model:{value:(_vm.sendMail),callback:function ($$v) {_vm.sendMail=$$v},expression:"sendMail"}},[_vm._v("\n                        Enviar e-mail\n                    ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }