<template>
    <div class="pd-20">
        <a-page-header
            class="mb-10 pd-0"
           
            sub-title=""
            @back="$router.go(-1)"
        >
            <div slot="title">
                <h1>
                    PAGAMENTOS -
                    <font class="cgreen">
                        ID {{ milesCard.id }} {{ milesCard.miles_program }}
                    </font>
                </h1>
            </div>
        </a-page-header>

        <a-row :gutter="[20, 20]">
            <a-col class="box box-contractor" :span="8">
                <a-row :gutter="[0, 0]">
                    <h2 class="title">
                        <a-icon class="mr-5" type="user" />
                        FORNECEDOR
                    </h2>
                    <supplier-details :supplier="milesCard.supplier" />
                </a-row>
            </a-col>

            <a-col class="box box-services" :span="16">
                <a-row :gutter="[0, 0]" style=" background: #f6f6f6;">
                    <h2 class="title">
                        DADOS BANCÁRIOS DO FORNECEDOR
                    </h2>

                    <a-row
                        v-for="bank in bankRows"
                        :key="bank.id"
                        class="view-data"
                        style="padding: 15px; margin: 0 0px; background: #fff;"
                        :gutter="15"
                    >
                        <a-col :span="10">
                            <div class="label">Banco</div>
                            <div class="value">
                                {{ milesCard.supplier.meta[`bank_${bank.id}`] }}
                            </div>
                        </a-col>
                        <a-col :span="4">
                            <div class="label">Agência</div>
                            <div class="value">
                                {{
                                    milesCard.supplier.meta[
                                        `bank_${bank.id}_agency`
                                    ]
                                }}
                            </div>
                        </a-col>

                        <a-col :span="6">
                            <div class="label">Conta</div>
                            <div class="value">
                                {{
                                    milesCard.supplier.meta[
                                        `bank_${bank.id}_account`
                                    ]
                                }}
                            </div>
                        </a-col>

                        <a-col :span="4">
                            <div class="label">Dígito</div>
                            <div class="value">
                                {{
                                    milesCard.supplier.meta[
                                        `bank_${bank.id}_account_digit`
                                    ]
                                }}
                            </div>
                        </a-col>
                    </a-row>
                    <!-- <pre>
                    {{milesCard}}
</pre> -->
                    <a-empty
                        :image="simpleImage"
                        v-if="bankRows == 0"
                        style=" background: #fff; padding: 30px 0; margin: 0"
                    >
                        <span slot="description">
                            Nenhum dado bancário cadastrado.
                        </span>
                        <a-button
                            @click="
                                $router.push(
                                    `/suppliers/edit/${milesCard.supplier.id}`
                                )
                            "
                        >
                            Cadastrar conta bancária
                        </a-button>
                    </a-empty>
                    <!-- 
                    <pre>
                    {{ milesCard.supplier }}

</pre
                    > -->
                    <h2 class="title">
                        PAGAMENTOS
                    </h2>

                    <a-collapse
                        class="payments-collapse"
                        expand-icon-position="right"
                        :bordered="false"
                    >
                        <a-collapse-panel
                            v-for="(payment, index) in groupPayments()"
                            :key="index"
                        >
                            <template slot="header">
                                <span>
                                    <a-icon type="dollar" class="mr-5" />
                                    {{ payment.transaction }} -
                                    <span
                                        v-html="
                                            formatOperation(
                                                payment.payments[0].transaction
                                            )
                                        "
                                    />
                                </span>

                                <font class="date">
                                    MILHAS DO LOTE:
                                    <font class="cblack">
                                        {{ payment.payments[0].miles_batch }}
                                    </font>
                                </font>

                                <font class="date">
                                    <!-- <a-icon type="calendar" class="mr-5" /> -->
                                    PRÓXIMO PAGAMENTO:
                                    <font class="cblack">
                                        {{
                                            checkExpirationDates(
                                                payment.payments,
                                                "pending"
                                            )
                                        }}
                                    </font>
                                </font>
                            </template>

                            <InstallmentPaymentForm
                                v-for="transaction in payment.payments"
                                :installment="transaction"
                                :milesCard="milesCard"
                                :key="transaction.id"
                            />

                            <!-- <pre>{{ payment.payments }}</pre> -->
                        </a-collapse-panel>
                    </a-collapse>
                    <!-- 
                    <pre>
        {{ groupPayments() }}
        </pre
                    > -->
                </a-row>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { Empty } from "ant-design-vue";
import SupplierDetails from "../../../../views/suppliers/SupplierDetails.vue";
import { format, parse } from "date-fns";
import _ from "lodash";
import InstallmentPaymentForm from "./forms/InstallmentPaymentForm.vue";

export default {
    name: "ViewPayments",
    components: { SupplierDetails, InstallmentPaymentForm },
    data() {
        return {
            milesCard: {},
            bankRows: [],
        };
    },
    beforeMount() {
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        this.getMilesCard();
    },
    methods: {
        checkExpirationDates(batchArr, status) {
            let expirationDate = "";
            let expirationDateTime = "";
            let price = "";
            let newbatchArr = batchArr;

            newbatchArr.reverse().forEach((payment) => {
                if (payment.status == status) {
                    console.log(payment.status, payment.expires_at);
                    expirationDate = payment.expires_at;
                    price = payment.value;
                }
            });

            newbatchArr.reverse();

            return expirationDate
                ? `${this.formatDate(
                      expirationDate
                  )} ${expirationDateTime} - R$ ${price}`
                : "NENHUM";
        },
        batchTotalValue(batchArr, status) {
            console.log(batchArr);

            let totalValue = 0;
            batchArr.forEach((payment) => {
                if (payment.status == status || "all" == status) {
                    totalValue += parseFloat(payment.value);
                }
            });

            let tr = parseFloat(totalValue);

            return tr.toLocaleString();
        },
        getMilesCard() {
            this.$http
                .post("/miles-card/details", {
                    id: this.$route.params.id,
                })
                .then(({ data }) => {
                    data;
                    this.milesCard = data;
                    this.bankRows =
                        data.supplier.meta.bank_rows != undefined
                            ? JSON.parse(data.supplier.meta.bank_rows)
                            : [];
                    document.title = `PAGAMENTOS - ${data.id} ${data.miles_program} - ${data.supplier.first_name} ${data.supplier.last_name}  `;
                });
        },
        formatDateTime(date) {
            let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
            return format(theDate, "dd/MM/yyyy HH:mm");
        },
        formatDate(date) {
            let theDate = parse(date, "yyyy-MM-dd", new Date());
            return format(theDate, "dd/MM/yyyy");
        },
        groupPayments() {
            let payments = _.groupBy(
                this.milesCard.payments.data,
                (item) => item.transaction_id
            );

            let values = Object.values(payments);
            let keys = Object.keys(payments);
            let allPayments = keys.map((transaction, index) => {
                return {
                    transaction: `LOTE ${transaction}`,
                    payments: values[index],
                };
            });

            return allPayments;
        },
        formatOperation(transaction) {
            let operation = "";
            let vcm = "";

            if (transaction.operation == "new") {
                if (transaction.is_vcm == "1") {
                    vcm = `<font class="corange" style="font-size: 24px; font-size: 16px;"> <a-tooltip> <template slot="title"> Milhas do VCM </template> • </a-tooltip> </font>`;
                }

                operation = `<font class="tag new">NOVO ${vcm}</font>`;
            }

            if (transaction.operation == "add") {
                operation = `<font class="tag add">ADIÇÃO</font>`;
            }

            if (transaction.operation == "subtract") {
                operation = `<font class="tag return">EMISSÃO</font>`;
            }

            if (transaction.operation == "canceled") {
                operation = `<font class="tag subtract">CANCELADO</font>`;
            }

            if (transaction.operation == "refund") {
                operation = `<font class="tag return">REEMBOLSO</font>`;
            }

            if (transaction.operation == "adjust") {
                operation = `<font class="tag adjust">AJUSTE</font>`;
            }

            if (transaction.operation == "purchase-canceled") {
                operation = `<font class="tag cancel-purchase">CANCELAMENTO DE COMPRA</font>`;
            }

            return operation;
        },
    },
};
</script>
