<template>
    <div>
        <a-form :form="form" @submit="handleSubmit">
            <a-row class="view-data payment-line" :gutter="15">
                <a-row :gutter="15">
                    <a-col :span="20">
                        <a-col :span="3">
                            <div class="label">ID</div>
                            <div class="value">
                                {{ installment.id }}
                            </div>
                        </a-col>
                        <a-col :span="3">
                            <div class="label">Parcela</div>
                            <div class="value">
                                {{ installment.installment_number }}
                            </div>
                        </a-col>

                        <a-col :span="5">
                            <div class="label">Qtd. de milhas</div>
                            <div class="value">
                                {{ installment.miles_quantity }}
                            </div>
                        </a-col>

                        <a-col :span="6">
                            <div class="label">Valor a pagar</div>
                            <div class="value">R$ {{ installment.value }}</div>
                        </a-col>

                        <!-- <a-col :span="5">
                            <div class="label">Data de Vencimento</div>
                            <div class="value">
                                {{ formatDate(installment.expires_at) }}
                            </div>
                        </a-col> -->

                        <a-col :span="6">
                            <div class="label">Data de Vencimento</div>
                            <a-form-item class="travel-input-outer">
                                <a-date-picker
                                    class="travel-input"
                                    :placeholder="`Data de Vencimento`"
                                    format="DD/MM/YYYY"
                                    :disabled="isPaid()"
                                    :showToday="false"
                                    :allowClear="false"
                                    v-decorator="[
                                        `expires_at`,
                                        {
                                            initialValue: installment.expires_at
                                                ? installment.expires_at
                                                : '',
                                        },
                                    ]"
                                >
                                </a-date-picker>
                            </a-form-item>
                        </a-col>

                        <a-col :span="5">
                            <div class="label">ID Voyage</div>
                            <a-form-item class="travel-input-outer">
                                <a-input
                                    type="number"
                                    class="travel-input"
                                    placeholder="Insira o ID"
                                    :disabled="isPaid()"
                                    v-decorator="[
                                        'voyage_id',
                                        {
                                            initialValue:
                                                installment.voyage_id != 0
                                                    ? installment.voyage_id
                                                    : '',
                                        },
                                    ]"
                                >
                                    <a-icon
                                        v-if="!isPaid()"
                                        slot="prefix"
                                        type="field-svg"
                                    />
                                </a-input>
                            </a-form-item>
                        </a-col>

                        <a-col :span="6">
                            <div class="label">Status do pagamento</div>

                            <a-form-item
                                class="travel-input-outer"
                                :class="form.getFieldValue('status')"
                            >
                                <a-select
                                    class="travel-input"
                                    show-search
                                    placeholder="Selecione"
                                    :disabled="
                                        $root.isAdmin() || $root.isFinancial()
                                            ? false
                                            : isPaid()
                                    "
                                    v-decorator="[
                                        'status',
                                        {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Obrigatório',
                                                },
                                            ],
                                        },
                                    ]"
                                >
                                    <a-select-option value="canceled">
                                        Cancelado
                                    </a-select-option>
                                    <a-select-option value="paid">
                                        Pago
                                    </a-select-option>
                                    <a-select-option value="pending">
                                        Pendente
                                    </a-select-option>
                                    <a-select-option value="refund">
                                        Reembolso
                                    </a-select-option>
                                    <a-select-option value="vcm">
                                        VCM
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <a-col :span="10">
                            <div class="label">Comprovante pagamento</div>

                            <div
                                class="uploaded-file mt-10"
                                v-if="paymentProof"
                                style="font-size: 10px; line-height: 1.2"
                            >
                                <a
                                    :href="installment.payment_proof"
                                    target="_blank"
                                    ><a-icon
                                        class="cblue"
                                        style="font-size: 30px"
                                        type="file-done"
                                /></a>
                                <a-icon
                                    v-if="installment.status != 'paid'"
                                    type="close-circle"
                                    @click="paymentProof = ''"
                                />
                            </div>

                            <div v-if="!paymentProof">
                                <a-upload
                                    name="file"
                                    :multiple="false"
                                    :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=miles-cards-payments&id=${installment.id}`"
                                    :remove="removeUploadedFile"
                                    :headers="{
                                        authorization: `Bearer ${token}`,
                                    }"
                                    @change="uploadedFile"
                                    v-model="paymentProof"
                                >
                                    <a-button
                                        type="dashed"
                                        class="mt-5"
                                        shape="circle"
                                    >
                                        <a-icon type="file" />
                                    </a-button>
                                </a-upload>
                            </div>
                        </a-col>

                        <a-col
                            v-if="form.getFieldValue('status') == 'refund'"
                            :span="24"
                        >
                            <div class="label">Motivo do reembolso</div>

                            <a-form-item class="textarea mb-0">
                                <a-input
                                    class="travel-textarea"
                                    placeholder="Escreva o motivo do reembolso"
                                    type="textarea"
                                    v-decorator="[
                                        `observation`,
                                        {
                                            initialValue:
                                                installment.observation,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Obrigatório',
                                                },
                                            ],
                                        },
                                    ]"
                                >
                                </a-input>
                            </a-form-item>
                        </a-col>
                    </a-col>
                    <a-col :span="4">
                        <a-button
                            class="mt-10"
                            type="primary"
                            style="width: 92%"
                            html-type="submit"
                            :disabled="
                                $root.isAdmin() || $root.isFinancial()
                                    ? false
                                    : isPaid()
                            "
                            :loading="loading"
                        >
                            Atualizar
                        </a-button>
                        <a-checkbox
                            class="mt-10"
                            v-model="sendMail"
                            style="
                                font-size: 12px;
                                font-weight: 500;
                                position: relative;
                            "
                            :disabled="true"
                        >
                            Enviar e-mail
                        </a-checkbox>
                    </a-col>
                </a-row>
            </a-row>
        </a-form>

        <!-- <pre>  {{ installment }}  </pre>
        <hr /> -->
    </div>
</template>

<script>
import { format, parse } from "date-fns";
import formatThings from "@/mixins/general/formatThings.js";

export default {
    props: {
        milesCard: Object,
        installment: Object,
    },
    mixins: [formatThings],
    data() {
        return {
            form: this.$form.createForm(this),
            sendMail: false,
            updateInstallment: {},
            loading: false,
            paymentProof: "",
            token: localStorage.getItem("token")
        };
    },
    mounted() {
        this.updateInstallment = this.installment;

        setTimeout(() => {
            this.form.setFieldsValue({
                status: this.installment.status,
            });

            this.paymentProof = this.installment.payment_proof;
        }, 100);
    },
    methods: {
        isPaid() {
            let flag = false;

            if (this.updateInstallment.status == "paid") {
                flag = true;
            }

            return flag;
        },
        formatDate(date) {
            let theDate = parse(date, "yyyy-MM-dd", new Date());
            return format(theDate, "dd/MM/yyyy");
        },
        formatDateTime(date) {
            let theDate = parse(date, new Date());
            return format(theDate, "dd/MM/yyyy HH:mm");
        },
        minDate(current) {
            console.log(current);
            return current < new Date();
        },
        removeUploadedFile(file) {
            console.log("remove", file);
            setTimeout(() => {
                this.paymentProof = "";
            }, 20);
        },
        uploadedFile(response) {
            console.log("uploadedFile", response);
            if (response.file.response.url != undefined) {
                //   console.log(response.file.response.url);
                this.paymentProof = response.file.response.url;
            }
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                console.log(">", err, values, this.updateInstallment);
                if (!err) {
                    this.updateInstallment.voyage_id = values.voyage_id;
                    this.updateInstallment.status = values.status;
                    this.updateInstallment.expires_at = values.expires_at;

                    this.updateInstallment.payment_proof = this.paymentProof;
                    if (values.status == "paid") {
                        if (
                            this.installment.payment_date ==
                            "0000-00-00 00:00:00"
                        ) {
                            this.updateInstallment.payment_date = format(
                                new Date(),
                                "yyyy-MM-dd HH:mm:ss"
                            );
                        }
                    } else {
                        this.updateInstallment.payment_date =
                            "0000-00-00 00:00:00";
                    }

                    this.updateInstallment.observation = values.observation;

                    this.updateInstallment.value = this.installment.value
                        .replace(".", "")
                        .replace(".", "")
                        .replace(".", "")
                        .replace(",", ".");

                    this.updateInstallment.miles_batch =
                        this.installment.miles_batch
                            .replace(".", "")
                            .replace(".", "")
                            .replace(".", "");
                    this.updateInstallment.miles_quantity =
                        this.installment.miles_quantity
                            .replace(".", "")
                            .replace(".", "")
                            .replace(".", "");
                    this.updateInstallment.user_id = this.installment.user.id;
                    this.updateInstallment.payment_user_id =
                        this.$store.state.userData.id;
                    this.updateInstallment.milescard_id = this.milesCard.id;

                    this.loading = true;

                    this.$http
                        .post("/payment/update", this.updateInstallment)
                        .then(({ data }) => {
                            data;
                            this.$message.success("Atualizado com sucesso!");
                            this.installment.value = this.formatPrice(
                                this.installment.value
                            );
                            this.loading = false;
                        })
                        .catch(({ res }) => {
                            res;
                            this.$message.error("Não foi possível atualizar.");
                            this.loading = false;
                        });
                    console.log("OK 2", this.updateInstallment);
                }
            });
        },
    },
};
</script>

<style lang="sass" scoped>
.uploaded-file
    position: relative
    .anticon.anticon-close-circle
        font-size: 16px
        color: red
        position: absolute
        left: 39px
        top: 0px
        border-radius: 10px
        background: #FFF
        cursor: pointer
</style>
